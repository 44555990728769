<template>
  <a-row justify="center" type="flex">
    <a-col :span="22">
      <div>
        <h1>Rapports</h1>
      </div>
      <a-tabs  default-active-key="1">
        <a-tab-pane key="1" tab="Type demandes">
          <a-spin :spinning="processing">
            <a-row justify="center" type="flex">
              <a-col :span="24">
                <a-table
                  :columns="columnsDemandes"
                  :dataSource="typeDemandes"
                  :pagination="false"
                  bordered
                  v-if="typeDemandes.length"
                >
                  <template slot="row_index" slot-scope="text, record, index">
                    <p>{{ index + 1 }}</p>
                  </template>
                  <template slot="actions" slot-scope="text, record, index">
                    <a-select
                      :default-value="record.rapport.id"
                      @change="
                        handleDemandeTypeRapportChange(record.id, $event)
                      "
                      style="width: 200px"
                    >
                      <a-select-option
                        :key="rapport.id"
                        v-for="rapport in rapports"
                      >
                        {{ rapport.nom_affichage }}
                      </a-select-option>
                    </a-select>
                  </template>
                </a-table>
                <EmptyResult v-else />
              </a-col>
            </a-row>
          </a-spin>
        </a-tab-pane>
        <a-tab-pane force-render key="2" tab="Type visites">
          <a-spin :spinning="processing">
            <a-row justify="center" type="flex">
              <a-col :span="24">
                <a-table
                  :columns="columnsVisites"
                  :dataSource="typeVisites"
                  :pagination="false"
                  bordered
                  v-if="typeVisites.length"
                >
                  <template slot="row_index" slot-scope="text, record, index">
                    <p>{{ index + 1 }}</p>
                  </template>
                  <template slot="actions" slot-scope="text, record, index">
                    <a-select
                      :default-value="record.rapport.id"
                      @change="handleVisiteTypeRapportChange(record.id, $event)"
                      style="width: 200px"
                    >
                      <a-select-option
                        :key="rapport.id"
                        v-for="rapport in rapports"
                      >
                        {{ rapport.nom_affichage }}
                      </a-select-option>
                    </a-select>
                  </template>
                </a-table>
                <EmptyResult v-else />
              </a-col>
            </a-row>
          </a-spin>
        </a-tab-pane>
        <a-tab-pane force-render key="3" tab="Type rapports">
          <a-spin :spinning="processing">
            <a-row justify="center" type="flex">
              <a-col :span="24">
                <a-table
                  :columns="columnsRapports"
                  :dataSource="typeRapports"
                  :pagination="false"
                  bordered
                  v-if="typeRapports.length"
                >
                  <template slot="row_index" slot-scope="text, record, index">
                    <p>{{ index + 1 }}</p>
                  </template>
                    <template slot="etats" slot-scope="text, record, index">
                      {{getEtats(record.etats)}}
                  </template>
                 <template slot="show_exercice" slot-scope="text, record, index">
                      {{record.show_exercice? 'Oui' : 'Non'}}
                  </template>
                  <template slot="statut_pro" slot-scope="text, record, index">
                      <EtatProfessionalList :etats="statutsProfessionels" :etat-professionnel="record.statut_professionnels" mode="multiple" 
                      @etat_professionnel_change="handleStatuProfessionelTypeRapportChange(record.id, $event)" />
                  </template>
                </a-table>
                <EmptyResult v-else />
              </a-col>
            </a-row>
          </a-spin>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import EmptyResult from "@/components/common/EmptyResult";
import EtatProfessionalList from "@/components/common/EtatProfessionalList";
import _map from "lodash-es/map";

let columnsDemandes = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Type demande",
    dataIndex: "nom_fr",
  },
  {
    title: "Type rapport",
    dataIndex: "rapport.type",
  },
  {
    title: "Nom d'affichage de modele",
    dataIndex: "rapport.nom_affichage",
  },
  {
    title: "Nom modele",
    dataIndex: "rapport.nom_modele",
  },
  {
    title: "Actions",
    scopedSlots: { customRender: "actions" },
  },
];
let columnsVisites = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Type demande",
    dataIndex: "nom_fr",
  },
  {
    title: "Type rapport",
    dataIndex: "rapport.type",
  },
  {
    title: "Nom d'affichage de modele",
    dataIndex: "rapport.nom_affichage",
  },
  {
    title: "Nom modele",
    dataIndex: "rapport.nom_modele",
  },
  {
    title: "Actions",
    scopedSlots: { customRender: "actions" },
  },
];
let columnsRapports = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Type rapport",
    dataIndex: "type",
  },
  {
    title: "Nom d'affichage de modele",
    dataIndex: "nom_affichage",
  },
  {
    title: "Nom modele",
    dataIndex: "nom_modele",
  },
  {
    title: "Afficher les exercices",
    scopedSlots: { customRender: "show_exercice" }
  },
   {
    title: "Les états associes",
    scopedSlots: { customRender: "etats" },
  },
  {
    title: "Etat Professionel",
    scopedSlots: { customRender: "statut_pro" },
  },
];
export default {
  name: "rapports",
  data() {
    return {
      processing: false,
      columnsDemandes,
      columnsVisites,
      columnsRapports,
    };
  },
  components: {
    EmptyResult,
    EtatProfessionalList
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      typeDemandes: "getTypeDemandes",
      typeVisites: "getTypeVisites",
      typeRapports: "getTypeRapports",
    }),
    ...mapState({
      rapports: (state) => state.rapports.rapports,
    
      statutsProfessionels: (state) => state.statuts.statuts,
    }),
  },
  methods: {
    handleDemandeTypeRapportChange(typeDemandeId, rapportId) {
      this.startProcessing();
      this.updateTypeDemandes({ id: typeDemandeId, rapport_id: rapportId })
        .then(() => this.getData())
        .finally(() => this.stopProcessing());
    },
    handleVisiteTypeRapportChange(typeVisiteId, rapportId) {
      this.startProcessing();
      this.updateTypeVisites({ id: typeVisiteId, rapport_id: rapportId })
        .then(() => this.getData())
        .finally(() => this.stopProcessing());
    },
     handleStatuProfessionelTypeRapportChange( rapportId, statuProfe) {
      this.startProcessing();
      this.updateRapport({ id: rapportId, statut_professionnels: statuProfe })
        .then(() => this.getRapports())
        .finally(() => this.stopProcessing());
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    getEtats(etats){
        return _map(etats, (etat) => etat.label);
    },
    getData() {
      this.startProcessing();
      this.$store.cache.dispatch("fetchRapports");
      this.getTypeDemandes()
        .then(() => {
          this.getTypeVisites();
        })
        .finally(() => this.stopProcessing());
    },
    ...mapActions({
      getRapports : "fetchRapports",
      getTypeDemandes: "fetchTypeDemandes",
      getTypeVisites: "fetchTypeVisites",
      updateTypeDemandes: "updateTypeDemandes",
      updateTypeVisites: "updateTypeVisites",
      updateRapport : "updateRapport"
    }),
  },
};
</script>
