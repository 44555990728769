<template>
  <a-select
    placeholder="Etat professionel"
    :allowClear="true"
    :mode="mode"
    :default-value="etatProfessionnel"
    @change="handleEtatProfessionnelChange"
    style="width: 100%"
  >
    <a-select-option value="">Tous</a-select-option>
    <a-select-option v-for="etat in etats" :key="etat.id">
      {{ etat.nom_fr }}
    </a-select-option>
  </a-select>
</template>
<script>
export default { 
  name: "EtatProfessionalList",
  props: { etats: Array, etatProfessionnel: Array, mode : {type : String, default : () => 'default'} },
  methods: {
    handleEtatProfessionnelChange(selectedItem) {
      this.$emit("etat_professionnel_change", selectedItem);
    }
  }
};
</script>
